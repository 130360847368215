import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GetStartStyled, PopupSetupStyled } from './styled';
import { BlockStack, Button, Collapsible, Icon, InlineStack, Popover, ProgressBar, Spinner, Text } from '@shopify/polaris';
import { StatusActiveIcon } from '@shopify/polaris-icons';
import { ChevronDownIcon } from '@shopify/polaris-icons';
import { images } from '@/asset';
import { useNavigate } from 'react-router-dom';
import { apiCaller } from '@/redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { link } from '@/constants/link';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import { PATH } from '@/constants';
import settingsSlice from '@/redux/slice/settings.slice';
import { IDScrollIntoView, OptionSettings } from '@/constants/enum';
import {
  handleHasClickGetStared,
  handleShowPopoverGetStared,
  hasClickGetStaredSelector,
  showPopoverGetStaredSelector,
} from '@/redux/slice/home.slice';
import { handleIdScrollIntoView } from '@/redux/slice/home.slice';

enum IDStep {
  Step1 = 'step1',
  Step2 = 'step2',
  Step3 = 'step3',
  Step4 = 'step4',
}

type Step = {
  step: IDStep;
  title: string;
  description: string;
  completed: boolean;
  buttonLabel: string;
  isLoading: boolean;
  onClick: () => void;
};

const GetStarted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isLoadingDataSettings = useSelector(loadingSelector);
  const showPopoverGetStared = useSelector(showPopoverGetStaredSelector);
  const hasClickGetStared = useSelector(hasClickGetStaredSelector);

  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });

  const [collapse, setCollapse] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const listSteps: Step[] = useMemo(
    () => [
      {
        step: IDStep.Step1,
        title: 'Activate app embed',
        description: "Activate to display Blockify's blocking page.",
        completed: !!checkEmbeddedStatus?.data?.status,
        buttonLabel: 'Go to theme editor',
        isLoading: checkEmbeddedStatus.isLoading,
        onClick: () => window.open(link.isOpenAppEmbedUrl, '_bank'),
      },
      {
        step: IDStep.Step2,
        title: 'Turn on Visitor Analytics',
        description: 'Turn on to track visitor access to your store.',
        completed: !!dataSettings?.settings.enableVisitorAnalytics,
        buttonLabel: 'Go to Visitor Analytics',
        isLoading: isLoadingDataSettings,
        onClick: () => navigate(PATH.ANALYTICS),
      },
      {
        step: IDStep.Step3,
        title: 'Turn on Fraud Order Analytics',
        description: 'Detect fraudulent orders and take timely action.',
        completed: !!dataSettings?.settings.user.orderScope,
        buttonLabel: 'Go to Fraud Order Analytics',
        isLoading: isLoadingDataSettings,
        onClick: () => navigate(PATH.FRAUD_ORDER),
      },
      {
        step: IDStep.Step4,
        title: 'Turn on Block VPN & Proxy',
        description: 'Block VPN and proxy users from attacking your store.',
        completed: !!dataSettings?.settings.activeVpnProxy,
        buttonLabel: 'Block VPN & Proxy',
        isLoading: isLoadingDataSettings,
        onClick: () => {
          dispatch(handleIdScrollIntoView(IDScrollIntoView.Proxy_and_vpn_blocker));
          dispatch(settingsSlice.actions.handleSelected([OptionSettings.BotKiller]));
          navigate(PATH.SETTINGS);
        },
      },
    ],
    [
      checkEmbeddedStatus?.data?.status,
      checkEmbeddedStatus.isLoading,
      dataSettings?.settings.activeVpnProxy,
      dataSettings?.settings.enableVisitorAnalytics,
      dataSettings?.settings.user.orderScope,
      dispatch,
      isLoadingDataSettings,
      navigate,
    ],
  );

  const totalCompleted = listSteps.reduce((acc, cur) => {
    const stepCompleted = cur.completed ? 1 : 0;
    return acc + stepCompleted;
  }, 0);

  useEffect(() => {
    if (isLoadingDataSettings || checkEmbeddedStatus.isLoading) return;

    const firstIncompleteStep = listSteps.find((step) => !step.completed)?.step;

    if (firstIncompleteStep && !hasClickGetStared) {
      setCollapse((props) => ({
        ...props,
        [firstIncompleteStep]: true,
      }));
      dispatch(handleShowPopoverGetStared(true));
    }
  }, [checkEmbeddedStatus.isLoading, isLoadingDataSettings, listSteps, hasClickGetStared, dispatch]);

  const handleCollapse = (key: keyof typeof collapse) => () => {
    const result = { ...collapse };
    Object.keys(result).forEach((k) => {
      result[k as keyof typeof result] = false;
    });
    setCollapse({
      ...result,
      [key]: !collapse[key],
    });
  };

  const togglePopoverActive = useCallback(() => {
    dispatch(handleShowPopoverGetStared(!showPopoverGetStared));
    if (!hasClickGetStared) dispatch(handleHasClickGetStared(true));
  }, [dispatch, hasClickGetStared, showPopoverGetStared]);

  const activator = (
    <div className="btn-get-started" onClick={togglePopoverActive}>
      <Text variant="headingMd" as="h2">
        Get started
      </Text>
      {isLoadingDataSettings || checkEmbeddedStatus.isLoading ? (
        <div className="d-flex flex-center mr-8">
          <Spinner size="small" />
        </div>
      ) : (
        <Icon source={StatusActiveIcon} tone="base" />
      )}
    </div>
  );

  return (
    <GetStartStyled id="GetStartStyled">
      <Popover activator={activator} autofocusTarget="first-node" onClose={togglePopoverActive} active={showPopoverGetStared}>
        <PopupSetupStyled>
          <BlockStack gap="200">
            <InlineStack blockAlign="center" align="space-between">
              <Text as="h4" variant="headingMd">
                Set up Blockify
              </Text>
              <Button onClick={togglePopoverActive} icon={ChevronDownIcon} variant="tertiary" />
            </InlineStack>

            <Text as="p" tone="subdued">
              Secure your store in 4 simple steps.
            </Text>

            <InlineStack blockAlign="center" align="space-between">
              <InlineStack gap="200" blockAlign="center" align="space-between">
                <div className="progress-bar-wrap">
                  <ProgressBar progress={(totalCompleted / 4) * 100} size="small" />
                </div>
              </InlineStack>

              <Text as="span">{totalCompleted}/4 completed</Text>
            </InlineStack>

            <BlockStack gap="050">
              {listSteps.map((step) => (
                <div
                  className={`set-item ${collapse[step.step] ? 'open-collapse' : ''}`}
                  key={step.step}
                  onClick={handleCollapse(step.step)}
                >
                  <div className="set-item-right">
                    <Button
                      loading={step.isLoading}
                      id="circle"
                      variant="plain"
                      icon={
                        step.completed ? (
                          <img src={images.circleChecked} width={20} alt="circle-tick" />
                        ) : (
                          <div className="circle" />
                        )
                      }
                    />
                  </div>

                  <BlockStack gap="150" inlineAlign="start">
                    <Text as="h5" variant={step.completed || collapse[step.step] ? 'headingMd' : undefined}>
                      {step.title}
                    </Text>
                    <Text as="h6" tone="subdued">
                      {step.description}
                    </Text>

                    <Collapsible
                      open={collapse[step.step]}
                      id=""
                      transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
                      expandOnPrint
                    >
                      <Button onClick={step.onClick} variant="primary">
                        {step.buttonLabel}
                      </Button>
                    </Collapsible>
                  </BlockStack>
                </div>
              ))}
            </BlockStack>
          </BlockStack>
        </PopupSetupStyled>
      </Popover>
    </GetStartStyled>
  );
};

export default GetStarted;
